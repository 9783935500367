<template>
  <div class="auth-wrapper auth-v2 custom_login_page mobile_bg">
    <b-row class="auth-inner m-0 mobile_bg">

      <!-- Brand logo-->
      <b-link class="brand-logo pdng_28">
        <!-- <vuexy-logo /> -->

        <span>
          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:190px"
            class="site_logo_mobile"
          />
        </span>
        <!-- <h2 class="brand-text text-warning ml-1">
          Task Force
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 section_last_mrgn"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            <!-- Welcome to EL TaskForce -->
            Welcome to TaskForce
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-0"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>

          <!-- form -->
          
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label="Email or Username"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="userEmail"
                name="login-email"
                placeholder="john@example.com"
              />
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
                <!-- <b-link :to="{}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <!-- checkbox -->

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="warning"
              block
            >
              Sign in
            </b-button>
          </b-form>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { LOGIN } from "../store/actions.type"

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      appLogoImage : require('@/assets/images/logo/task-force-full.png'),
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      email,
      showDismissibleAlert:false,
      error_message:''
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      
      this.$store.dispatch(LOGIN, {
                        data:{
                            email: this.userEmail,
                            password:this.password,
                            role:['administrator','admin','director', 'senior_operation_manager', 'operation_manager', 'operation_executive','site_client','site_manager','supervisor','crew']
                        },
                        api:'/api/client-login'
                    })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
              
              var userData = this.$store.getters.currentUser;
              
              userData.ability = [{
                action: "manage", subject: "all"
              }];

              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Login Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    /*this.$router.push({ name:'client-dashboard'});*/
                    /*if (userData.role == 'site_client') {
                      window.location.replace('/site/dashboard');
                    }else{

                    }*/
                    window.location.replace('/client/dashboard');
                });
            }       
        }); 
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
